import React from "react"

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';

const Disclaimer = () => {
  return (
    <Layout>
        <SEO 
            title="Legal Disclaimer | Mike Martin Law"
        />
        <Card>
            <h1 className="text-4xl font-bold mb-8">Legal Disclaimer – Advertising Material</h1>
            <p>The material on this site has been prepared by Michael S. Martin for informational purposes only. This information should not be construed as legal advice and is not necessarily current or complete.</p>
            <p>Receipt of this information does not create an attorney-client relationship. You are urged to consult a lawyer concerning your particular fact situation and any specific legal questions you may have.</p>
            <p>This site is not an offer to represent you and Michael S. Martin does not wish to represent you in any jurisdiction in which he is not admitted to practice or any jurisdiction in which this site does not comply with all applicable laws and ethical rules. If you are seeking representation in a jurisdiction other than Kansas or Missouri, Michael S. Martin  may be able to assist you in finding competent local counsel in your jurisdiction.</p>
            <p>You should not send Michael S. Martin any confidential information until after you have requested him to represent you and he have agreed to do so. In order to make sure that your interests do not conflict with those of his present clients, a conflicts check must be performed before agreeing to represent you.</p>
            <p>You also should be aware that any communication sent by e-mail may be intercepted by third parties who may be under no obligation to keep such communication confidential. Before sending any confidential information, you should contact Michael S. Martin to arrange suitable means to protect it.</p>
            <p>The choice of a lawyer is an important decision and should not be based solely upon advertisements.</p>
        </Card>
    </Layout>
  )
}

export default Disclaimer;